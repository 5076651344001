import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import './App.css';

function MovieDetail({ movie }) {
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [currentEpisodeUrl, setCurrentEpisodeUrl] = useState('');

  const handleEpisodeClick = useCallback((playUrl) => {
    const decodedUrl = decodeURIComponent(playUrl);
    setSelectedEpisode(`https://bofang.ikdmjx.com/?url=${decodedUrl}`);
    setCurrentEpisodeUrl(playUrl);
  }, []);

  useEffect(() => {
    if (movie.vod_play_url) {
      const firstEpisode = movie.vod_play_url.split('#')[0].split('$')[1];
      handleEpisodeClick(firstEpisode);
    }
  }, [movie, handleEpisodeClick]);

  return (
    <div className="movie-detail">
      <h2>{movie.vod_name}</h2>

      {selectedEpisode && (
        <div className="player">
          <iframe
            src={selectedEpisode}
            width="100%"
            height="400"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}
      
      {movie.vod_play_url && (
        <div className="episodes">
          <h3>剧集列表</h3>
          {movie.vod_play_url.split('#').map((episode, index) => {
            const [name, url] = episode.split('$');
            return (
              <button 
                key={index} 
                onClick={() => handleEpisodeClick(url)}
                className={url === currentEpisodeUrl ? 'active' : ''}
              >
                {name}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;
    setLoading(true);
    setError('');
    setSearchResults([]);
    setSelectedMovie(null);
    try {
      const response = await axios.get(`https://movie.91aitv.com/api.php/provide/vod/?ac=list&wd=${encodeURIComponent(searchTerm)}`);
      if (response.data.list && response.data.list.length > 0) {
        // 异步加载每个搜索结果的详细信息
        response.data.list.forEach(async (movie) => {
          try {
            const detailResponse = await axios.get(`https://movie.91aitv.com/api.php/provide/vod/?ac=detail&ids=${movie.vod_id}`);
            const detailedMovie = detailResponse.data.list ? detailResponse.data.list[0] : movie;
            setSearchResults(prevResults => [...prevResults, detailedMovie]);
          } catch (error) {
            console.error('获取详情出错:', error);
            setSearchResults(prevResults => [...prevResults, movie]);
          }
        });
      } else {
        setError('未找到相关影视资源。');
      }
    } catch (error) {
      console.error('搜索出错:', error);
      setError('搜索时发生错误，请稍后再试。');
    } finally {
      setLoading(false);
    }
  };

  const handleMovieClick = (movie) => {
    setSelectedMovie(movie);
  };

  const handleCloseDetail = () => {
    setSelectedMovie(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>影视资源搜索</h1>
        <div className="search-container">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="输入影视名称..."
          />
          <button onClick={handleSearch}>搜索</button>
        </div>
      </header>
      <main>
        {loading && <p className="loading">加载中...</p>}
        {error && <p className="error">{error}</p>}
        {selectedMovie ? (
          <MovieDetail movie={selectedMovie} onClose={handleCloseDetail} />
        ) : (
          <div className="search-results">
            {searchResults.map((movie) => (
              <div key={movie.vod_id} className="movie-item" onClick={() => handleMovieClick(movie)}>
                <img src={movie.vod_pic} alt={movie.vod_name} />
                <h3>{movie.vod_name}</h3>
              </div>
            ))}
          </div>
        )}
      </main>
    </div>
  );
}

export default App;